<template>
	<div class="box">
		<!-- 头部 -->
		<headers></headers>
		<!-- 内容 -->
		<div class="con">
			<div class="con-left">
				<div class="con-left-top">
					<div class="con-l">
						<div class="con-l-title"><span>数据线数据</span> <img src="../../assets/images/jin.png" alt=""></div>
						<div class="scx">
							<div class="rkclsl">
								<progress value="10000" max="10000"></progress>
								<div><span>机制砂数量（累计）</span>
									<p>10000<span>T</span></p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress value="1600" max="10000"></progress>
								<div><span>今日生产数量（累计）</span>
									<p>1600<span>T</span></p>
								</div>
							</div>
							<div class="other-sj">
								<div class="other-sj-item">
									<span>本周（生产数量）</span>
									<p>600 <span>T</span> </p>
								</div>
								<div class="other-sj-item">
									<span>本月（生产数量）</span>
									<p>1000 <span>T</span> </p>
								</div>
								<div class="other-sj-item">
									<span>实时生产效率</span>
									<p>0.6<span>T/H</span> </p>
								</div>
							</div>
						</div>
						<!-- 出入库数据 -->
						<div class="con-l-title crk-title"><span>出入库数据</span> <img src="../../assets/images/jin.png"
								alt="">
						</div>
						<div class="crkdata">
							<div class="crkdata-top">
								<div class="crkdata-top-item">
									<span>当前场内存量</span>
									<p>336 <span>T</span></p>
								</div>
								<div class="crkdata-top-item">
									<span>今日原材料入场</span>
									<p>2336<span>T</span></p>
								</div>
							</div>
						</div>
						<!-- 今日成品料出场 -->
						<div class="jrcpl">
							<dv-active-ring-chart :config="config2" style="width:280px;height:300px" />
							<div class="jrcpl-list">
								<div class="jrcpl-list-item">
									<div class="jrcpl-item">
										<div class="jrcpl-item-con">
											<div></div>
											<span>成品料一</span>
										</div>
										<p>800 <span>T</span> </p>
									</div>
									<div class="dian"></div>
								</div>
								<div class="jrcpl-list-item">
									<div class="jrcpl-item">
										<div class="jrcpl-item-con">
											<div class="cpl2"></div>
											<span>成品料二</span>
										</div>
										<p>800 <span>T</span> </p>
									</div>
									<div class="dian"></div>
								</div>
								<div class="jrcpl-list-item">
									<div class="jrcpl-item">
										<div class="jrcpl-item-con">
											<div class="cpl3"></div>
											<span>成品料三</span>
										</div>
										<p>800 <span>T</span> </p>
									</div>
									<div class="dian"></div>
								</div>
							</div>
						</div>
					</div>
					<!-- 中间部分 -->
					<div class="con-center">
						<div class="con-center-item ">
							<div class="jk ">
								<img src="../../assets/images/jk.jpg" alt="">
								<div class="con-center-item-top">
									<div>
										<img src="../../assets/images/Frame.png" alt="">
										<span>监控名称</span>
									</div>
								</div>
								<div class="con-center-item-bottom">
									<span>2022年11月16日 14:26:36</span>
								</div>
							</div>

						</div>
						<div class="con-center-item green">
							<div class="jk ">
								<img src="../../assets/images/jk.jpg" alt="">
								<div class="con-center-item-top">
									<div>
										<img src="../../assets/images/Frame.png" alt="">
										<span>监控名称</span>
									</div>
									<img src="../../assets/images/Frame(1).png" alt="">
								</div>
								<div class="con-center-item-bottom">
									<span>2022年11月16日 14:26:36</span>
								</div>
							</div>

						</div>
						<div class="con-center-item  yellow">
							<div class="jk">
								<img src="../../assets/images/jk.jpg" alt="">
								<div class="con-center-item-top">
									<div>
										<img src="../../assets/images/Frame.png" alt="">
										<span>监控名称</span>
									</div>
									<img src="../../assets/images/Frame(2).png" alt="">
								</div>
								<div class="con-center-item-bottom">
									<span>2022年11月16日 14:26:36</span>
								</div>
							</div>

						</div>
						<div class="con-center-item red">
							<div class="jk ">
								<img src="../../assets/images/jk.jpg" alt="">
								<div class="con-center-item-top">
									<div>
										<img src="../../assets/images/Frame.png" alt="">
										<span>监控名称</span>
									</div>
									<img src="../../assets/images/Frame(3).png" alt="">
								</div>
								<div class="con-center-item-bottom">
									<span>2022年11月16日 14:26:36</span>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="con-left-buttom">
					<div class="title"><span>地磅实时数据</span> <img src="../../assets/images/jin.png" alt="">
					</div>
					<!-- <dv-scroll-board :config="config3" style="width:100%;height:150px" /> -->
					<div class="db-t">
						<div v-for="(name,index) in header" :key="index" class="db-t-h" >{{name}}</div>
					</div>
					<div v-for="item in dblist" :key="item.id" class="db-b">
						<div class="">
							<span style="width: 60px;">{{item.xuhao}}</span>
							<span>{{item.huoming}}</span>
							<span>{{item.guige}}</span>
							<span v-if="item.type==1">出厂</span>
							<span v-if="item.type==2">入厂</span>
							<span>{{item.chehao}}</span>
							<span>{{item.maozhong}}</span>
							<span>{{item.pizhong}}</span>
							<span>{{item.jingzhong}}</span>
							<span>{{item.gbname}}</span>
							<span style="width: 200px;">{{item.fguobang}}</span>
							<span style="width: 200px;">{{item.sguobang}}</span>
						</div>
					</div>
			</div>
			</div>

			<div class="con-r">
				<div class="con-l-title"><span>安全警告</span> <img src="../../assets/images/jin.png" alt=""></div>
				<!-- 安全警告 -->
				<div class="aqjg">
					<img src="../../assets/images/redyuj.png" alt="">
					<img src="../../assets/images/y-yuj.png" alt="">
					<img src="../../assets/images/g-yj.png" alt="">
				</div>
				<!-- 人力资源 -->
				<div class="con-l-title rlzy-title"><span>人力资源</span> <img src="../../assets/images/jin.png" alt="">
				</div>
				<div class="rlzy-list">
					<div class="rlzy-item">
						<div class="rlzy-l">
							<img src="../../assets/images/Group 27.png" alt="">
							<span>工厂总人数</span>
						</div>
						<p>256 <span>人</span> </p>
					</div>
					<div class="rlzy-item">
						<div class="rlzy-l">
							<div></div>
							<span>在岗</span>
						</div>
						<p>256 <span>人</span> </p>

					</div>
					<div class="rlzy-item">
						<div class="rlzy-l">
							<div></div>
							<span>今日值班</span>
						</div>
						<p>56 <span>人</span> </p>

					</div>
					<div class="rlzy-item">
						<div class="rlzy-l">
							<div></div>
							<span>今日休班</span>
						</div>
						<p>66 <span>人</span> </p>

					</div>
				</div>
				<!-- 电力资源 -->
				<div class="con-l-title rlzy-title "><span>电力资源</span> <img src="../../assets/images/jin.png" alt="">
				</div>
				<div class="dlzy">
					<div class="dlzy-top">
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="">
							<div>
								<p>5784 <span>kw.h</span> </p>
								<span>今日耗电量</span>
							</div>
						</div>
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="">
							<div>
								<p>5784 <span>kw.h</span> </p>
								<span>今日耗电量</span>
							</div>
						</div>
					</div>
					<div id="line"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from '../../components/headers.vue';
	import LivePlayer from '@liveqing/liveplayer'
	import * as echarts from 'echarts';
	const config2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '成品料1',
				value: 800
			},
			{
				name: '成品料2',
				value: 600
			},
			{
				name: '成品料3',
				value: 500
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	}
	const config3 = {
		header: ['序号', '货名', '规格', '出入标识', '卡号', '车号', '毛重', '皮重', '净重', '过磅员', '过磅时间'],
		data: [],
		headerBGC: '#000f28',
		evenRowBGC: '#000f28',
		oddRowBGC: '#000f28',
		columnWidth: [
			60, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140
		],
	}
	export default {
		components: {
			headers,
			LivePlayer
		},
		data() {
			return {
				header: ['序号', '货名', '规格', '出入标识', '车号', '毛重', '皮重', '净重', '过磅员', '首次过磅时间','二次过磅时间'],
				config2,
				dblist:[],
				config3
			}
		},
		created(){
			this.getdblist()
			let timer = setInterval(()=>{
				this.getdblist()
			},30000)
		},
		mounted() {
			var chartDom = document.getElementById('line');
			console.log(chartDom)
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['11-11', '11-12', '11-13', '11-14', '11-15']
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: [2000, 4000, 1600, 2000, 4600],
					type: 'line',
					areaStyle: {}
				}]
			};

			option && myChart.setOption(option);
			this.getshexiang()
		},
		methods:{
			// 获取地磅数据列表
			async getdblist() {
				 let timer2
				let res = await this.$postToken('datas/dibang-list', {
					time: "",
					page_index:1,
					page_size:4
				});
				if (res.data.code == 200) {	
					this.dblist = res.data.result.list
				}
			},
			// sp error
			restartPlayer() {},
			// 获取摄像头
			async getshexiang(e){
				let res = await this.$postToken('datas/shexiang-data')
				if(res.data.code==200){
					this.videoList = res.data.result
				}
			},
		}
	}
</script>
<style scoped>
	.header {
		width: 100%;
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 36px;
		box-sizing: border-box;
		overflow: hidden;
		margin-bottom: 30px;
	}

	.header .header-time {
		width: 515px;
		height: 60px;
		display: flex;
		align-items: center;
		/*   */
		background: url(../../assets/images/header-l.png)no-repeat;
	}

	.header .header-time span:nth-child(1) {
		font-size: 32px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 38px;
	}

	.header .header-time span:nth-child(3) {
		font-size: 20px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #D0DEEE;
		line-height: 23px;
	}

	.header .header-time .fgx {
		height: 20px;
		width: 0px;
		opacity: 1;
		border: 1px solid;
		border-image: linear-gradient(270deg, rgba(108, 128, 151, 1), rgba(108, 128, 151, 0)) 1 1;
		margin: 0 1%;
	}

	.header-title {
		width: 836px;
		height: 90px;
		line-height: 90px;
		font-size: 32px;
		color: #FFFFFF;
		/* padding: 2% 2.5%; */
		text-align: center;
		box-sizing: border-box;
		background: url(../../assets/images/title.png)no-repeat;
	}

	.header-r {
		width: 507px;
		height: 60px;
		padding-left: 200px;
		line-height: 60px;
		box-sizing: border-box;
		background: url(../../assets/images/header-r.png)no-repeat;
		color: #D0DEEE;
	}

	.header-r span {
		font-size: 32px;
		padding-right: 36px;
		color: #FFFFFF;
	}

	/* 内容 */
	.con {
		width: 100vw;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
	}

	.con-left {
		/* width: 76%; */
		flex: 1;
	}

	.con-left .con-left-top {
		display: flex;
		justify-content: space-between;
	}

	/* 内容左侧 */
	.con-l {
		margin-left: 36px;
		width: 400px;
		height: 100%;
		box-sizing: border-box;
	}

	.con-l-title {
		margin: 10px 0 10px 0;
		height: 40px;
		font-size: 20px;
		padding: 0 0 20px 12%;
		box-sizing: border-box;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #FFFFFF;
		background: url(../../assets/images/title\(1\).png)no-repeat;
		display: flex;
		align-items: center;
	}

	.con-l-title span {
		display: block;
		padding-bottom: 20px;
	}

	.con-l-title img {
		width: 12px;
		height: 12px;
		margin: 0 0 1% 3%;
	}

	/* 数据 */

	.scx progress {
		width: 100%;
		height: 10px;
		border-radius: 0;
	}

	.scx progress::-webkit-progress-bar {

		background: rgba(21, 154, 255, 0.2);

	}

	.scx progress::-webkit-progress-value {

		background: linear-gradient(90deg, #159AFF 0%, #66E1DF 100%);

	}

	.rkclsl div {
		width: 100%;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: space-between;
	}

	.rkclsl div span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #D0DEEE;
	}

	.rkclsl div p {
		font-size: 24px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;
	}

	.clscsl {
		margin-top: 2%;
	}

	/* 其他生产线数据 */
	.other-sj {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		margin-bottom: 15%;
	}

	.other-sj .other-sj-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: .2% 0;
	}

	.other-sj .other-sj-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #D0DEEE;
	}

	.other-sj .other-sj-item p {
		color: #fff;
		font-size: 20px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
	}

	/* 出入库数据 */
	.crkdata-top {
		display: flex;
		justify-content: space-between;
	}

	.crkdata-top-item {
	width: 165px;
	height: 68px;
	background: linear-gradient(90deg, rgba(21, 154, 255, 0) 0%, rgba(3, 26, 62) 31%, rgba(3, 26, 62) 69%, rgba(21, 154, 255, 0) 100%);
	border-left: 2px solid #159AFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	}

	.crkdata-top-item span {
		color: #D0DEEE;
		font-size: 14px;
	}

	.crkdata-top-item p {
		color: #fff;
		font-weight: 600;
		font-size: 24px;
	}

	/* 今日成品料出厂 */
	.jrcpl {
		display: flex;
		align-items: center;
	}

	.jrcpl-item .jrcpl-item-con {
		display: flex;
		align-items: center;
	}

	.jrcpl-list {
		width: 65%;
		height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.jrcpl-list-item {
		display: flex;
		align-items: flex-end;
	}

	.jrcpl-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(108, 128, 151, 0.5);
	}

	.jrcpl-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #D0DEEE;
	}

	.jrcpl-item p {
		font-size: 18px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #FFFFFF;
	}

	.jrcpl-item div div {
		width: 10px;
		height: 10px;
		background: #159AFF;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		margin-right: 10px;
	}

	.dian {
		width: 4px;
		height: 4px;
		background: #6C8097;
		border-radius: 100%;
	}

	.jrcpl-item div .cpl2 {
		background: #d0deee;
	}

	.jrcpl-item div .cpl3 {
		background: #66e1df;
	}

	/* 中间内容 监控 */
	.con-center {
		margin: 0 30px;
		flex:1;
		display: flex;
		flex-wrap: wrap;
		/* justify-content: space-around; */
	}

	.con-center-item {
		width: 240px;
		height: 147px;
		margin-left: 1%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(../../assets/images/jk-bg.png);
	}

	.con-center-item .jk img {
		display: block;
		width: 224px;
		height: 126px;
	}

	.con-center-item .jk {
		width: 224px;
		height: 126px;
		position: relative;
	}

	.red {
		background: url(../../assets/images/jkr-bg.png);
	}

	.yellow {
		background: url(../../assets/images/jky-bg.png);
	}

	.green {
		background: url(../../assets/images/jkg-bg.png);
	}

	.con-center-item-top {
		padding-right: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.con-center-item .jk .con-center-item-top {
		position: absolute;
		top: 0px;
		z-index: 9;
		width: 100%;
		height: 26px;
		line-height: 26px;
		background: rgba(0, 15, 40, 0.68);
		border-radius: 0px 0px 0px 0px;

	}

	.con-center-item .jk .con-center-item-top div {
		padding: 0 10px;
		display: flex;
		align-items: center;
		color: #66E1DF;
		font-size: 14px;
		width: 80px;
		justify-content: space-between;
	}

	.con-center-item .jk .con-center-item-top img {
		width: 18px;
		height: 18px;
	}

	.con-center-item .jk .con-center-item-bottom {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: #fff;
		font-size: 12px;
	}

	.con-r {
		width: 370px;
		margin-right: 36px;
		box-sizing: border-box;
	}

	/* 安全预警 */
	.aqjg {
		width: 100%;
		margin-bottom: 40px;
		display: flex;
		justify-content: space-around;
	}

	.aqjg img {
		width: 76px;
		height: 92px;
	}

	/* 人力资源 */
	.rlzy-title {
		margin: 0 0 20px 0;
	}

	.rlzy-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 40px;
	}

	.rlzy-item {
		width: 335px;
		margin-bottom: 16px;
		padding: 10px 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(21, 154, 255, 0.1);
	}

	.rlzy-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #D0DEEE;
	}

	.rlzy-item .rlzy-l {
		display: flex;
		align-items: center;
	}

	.rlzy-item .rlzy-l img {
		display: block;
		margin-right: 10px;
		width: 42px;
		height: 42px;
	}

	.rlzy-item .rlzy-l div {
		width: 2px;
		height: 20px;
		background: #66E1DF;
		margin-right: 10px;
	}

	.rlzy-item p {
		font-size: 20px;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #FFFFFF;
	}

	/* 电力资源 */

	.dlzy-top {
		display: flex;
		justify-content: space-between;
	}

	.dlzy-item {
		display: flex;
		align-items: center;
	}

	.dlzy-item span {
		font-size: 12px;
		color: #D0DEEE;
	}

	.dlzy-item p {
		font-size: 24px;
		color: #fff;
	}

	#line {
		width: 100%;
		height: 300px;
	}

	/* 地磅实时数据 */
	.con-left .title {
		height: 40px;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		padding: 0 0 20px 50px;
		margin-left: 1%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
	}

	.con-left .title span {
		display: block;
		margin-bottom: 10px;
	}

	.con-left .title img {
		display: block;
		width: 12px;
		height: 12px;
		margin-left: 10px;
	}
	
	.db-t{
		width: 100%;
		color: #D0DEEE;
		margin-bottom: 10px;
		text-align: center;
		display: flex;
		align-items: center;
	}
	.db-t-h{
		width: 120px;
		font-size: 14px;
	}
	.db-t-h:nth-last-child(1),.db-t-h:nth-last-child(2){
		width: 200px;
	}
	.db-t-h:nth-child(1){
		width: 60px;
	}
	.db-b{
		font-size: 13px;
		color: #fff;
		padding: 6px 0;
	}
	.db-b div{
		display: flex;
		align-items: center;
	}
	.db-b div span{
		width: 120px;
		display: block;
		text-align: center;
	}
	
</style>
